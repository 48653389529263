<template>
    <div>
        <!-- <modal
        class="new-modal-default"
        :name="modal_name"
        transition="nice-modal-fade"
        :delay="100"
        width="95%"
        :height="600"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        >
            <div class="v-modal-content" style="background-color: #ffffff !important;height: inherit;">
                <div class="v-modal-header" style="background-color: #fff !important;">
                    <div>
                        <span class="v-modal-dialog-title text-secondary" style="color: #003cb9 !important;">Create Branch</span>
                    </div>
                </div>
                <div class="v-modal-body">
                    <div class="v-modal-layout"> -->
                    <div class="tabs-content-secondblock mt-3">
                        <div class="row">
                            <div class="col-lg-12 mb-3">
                                <div class="d-flex align-items-center justify-content-between" style="border-bottom: 2px solid rgb(172, 174, 177);border-top: 2px solid rgb(172, 174, 177);">
                                    <div>
                                        <span class="fs-14 pl-4 fw-600 align-items-center">Headquarter and Branches <span class="fs-14 pl-1 pr-1">></span></span>
                                        <!-- <i class="icon-chevron-right align-items-center fs-26 fw-600 text-center"></i> -->
                                        <span v-if="$route.query.branch == 'add_branch'" class="fs-14 fw-500 align-items-center defaultDarkBlue">Create Branch</span>
                                        <span v-else class="fs-14 fw-500 align-items-center defaultDarkBlue"> Edit Branch</span>
                                        
                                    </div>
                                    <!-- <div class="pt-2 pl-4 pr-3 pb-2" @click="goBack()" style="text-align:right;cursor: pointer;">
                                        <button class="arrow-circle fs-14 fw-600">{{'<-'}}</button>
                                        <span class="fs-14 pl-1">Back</span>
                                    </div> -->
                                    <div class="pt-2 pl-4 pr-3 pb-2" v-if="fields_not_changed == false" @click="showGoBackPopup()" style="text-align:right;cursor: pointer;">
                                        <!-- <img class="pointer" width="25" @click="goBack()" src="/static/images/back_page_arrow.svg"> -->
                                        <button class="arrow-circle fs-14 fw-600">{{'<-'}}</button>
                                        <span class="fs-14 pl-1">Back</span>
                                    </div>
                                    <div class="pt-2 pl-4 pr-3 pb-2" v-else @click="$router.go(-1)" style="text-align:right;cursor: pointer;">
                                        <button class="arrow-circle fs-14 fw-600">{{'<-'}}</button>
                                        <span class="fs-14 pl-1">Back</span>
                                    </div>
                                </div>  
                            </div>
                        </div>
                        <div class="create-company-header d-flex align-items-center justify-content-between">
                            <span v-if="$route.query.branch == 'add_branch'" class="fs-14 text-white pl-10">Create Branch</span>
                            <span v-else class="fs-14 text-white pl-10">Edit Branch</span>
                            <span class="pointer pr-2" @click="expandTabs('branch_info')">
                                <i class="text-white" :class="{'icon-chevron-double-up':branch_info_expand, 'icon-chevron-double-down':!branch_info_expand}" style="font-size: 20px;"></i>
                            </span>
                        </div>
                        <div :class="{'d-none':!branch_info_expand}" class="px-8">
                            <div>
                                <div v-if="$route.query.branch == 'add_branch'"></div>
                                <div v-else class="mt-3">
                                    <div class="d-flex align-items-center new-contactView ">
                                        <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Assign as Headquarter </div>
                                        <div class="w-100 ml-2" style="position: relative;">
                                            <el-switch
                                                v-model="assign_as_headquarter"
                                                @change="setAsHeadquarter"
                                                :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                active-color="#00448b"
                                                inactive-color="#dcdfe6">
                                            </el-switch>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                        <div class="w-100 position-relative">
                                            <span class="input-label">Branch Name<span class="pl-1 text-danger">*</span>
                                                <sup>
                                                    <el-tooltip class="item" effect="dark"
                                                        placement="top-start" content="Change options from settings">
                                                        <i class="icon-information text-secondary" style="font-size: 14px"></i>
                                                    </el-tooltip>
                                                </sup>
                                            </span>
                                            <div class="d-flex align-items-center mt-2">
                                                <input type="text" maxlength="100" v-validate="'required'" v-model="branch_name" class="input-field"
                                                    placeholder="Enter Branch Name" name="branch_name" />
                                            </div>
                                            <div class="text-right" style="position: absolute;right: 0;">
                                                <span class="input-field-length">{{ (branch_name).length }}/100</span>
                                            </div>
                                            <span class="invalid-feedback-form text-danger" v-show="errors.has('branch_name')">
                                                {{errors.first('branch_name')}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                        <div class="w-100 position-relative">
                                            <span class="input-label">Branch Code 
                                                <sup>
                                                    <el-tooltip class="item" effect="dark"
                                                        placement="top-start" content="No Special Character except /_-">
                                                        <i class="icon-information text-secondary" style="font-size: 14px"></i>
                                                    </el-tooltip>
                                                </sup>
                                            </span>
                                            <div class="d-flex align-items-center mt-2">
                                                <input type="text" maxlength="100" v-model="branch_code" class="input-field"
                                                    placeholder="Enter Branch Code" name="branch_code" />
                                            </div>
                                            <div class="text-right" style="position: absolute;right: 0;">
                                                <span class="input-field-length">{{ (branch_code).length }}/100</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                        <div class="w-100">
                                            <span class="input-label">Date of Incorporation(DD/MM/YYYY)</span>
                                            <div class="d-flex align-items-center mt-2">
                                                <date-picker class="profile-date-picker calendar-edit-per input-field pt-0 pb-0 pr-0" v-model="date_of_incorporation" value-type="format" lang="en" :not-after="new Date()" 
                                                :clearable="false" placeholder="DD/MM/YYYY"
                                                :editable="false" style="padding-top: 5px !important; padding-left: 2px !important;"
                                                type="date" format="DD/MM/YYYY"></date-picker>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                        <div class="w-100">
                                            <span class="input-label">Time Zone<span class="pl-1 text-danger">*</span>
                                                    <sup>
                                                        <el-tooltip class="item" effect="dark"
                                                            placement="top-start" content="Change options from settings">
                                                            <i class="icon-information text-secondary" style="font-size: 14px"></i>
                                                        </el-tooltip>
                                                    </sup>
                                            </span>
                                            <div class="d-flex align-items-center mt-2">
                                                <multiselect class="diginew-multiselect multiselect-height-issue-fix" name="time_zone" v-validate="'required'" label="label" :show-labels="false" :loading="loading" :searchable="true" :options="time_zone_options" v-model="time_zone" placeholder="Select Time Zone">
                                                    <template slot="noOptions">
                                                        <div class="text-secondary text-center fw-600">List is Empty</div>
                                                    </template>
                                                    <template slot="noResult">
                                                        <div class="text-secondary text-center fw-600">No Results Found</div>
                                                    </template>
                                                </multiselect>
                                            </div>
                                            <span class="invalid-feedback-form text-danger" v-show="errors.has('time_zone')">
                                                {{errors.first('time_zone')}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                        <div class="w-100">
                                            <span class="input-label">Number of Employees</span>
                                            <div class="d-flex align-items-center mt-2">
                                                <input type="text" maxlength="8" v-validate="'numeric'" class="input-field" v-model.number="no_of_employees" placeholder="Enter No of Employees" name="no_of_employees" />
                                            </div>
                                            <span class="invalid-feedback-form text-danger" v-show="errors.has('no_of_employees')">
                                                {{errors.first('no_of_employees')}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-6">
                                <div class="create-company-header d-flex align-items-center justify-content-between">
                                    <span class="fs-14 text-white">Branch Communication Info</span>
                                    <span class="pointer" @click="expandTabs('communication_info')">
                                        <i class="text-white" :class="{'icon-chevron-double-up':communication_info_expand, 'icon-chevron-double-down':!communication_info_expand}" style="font-size: 20px;"></i>
                                    </span>
                                </div>
                                <div :class="{'d-none':!communication_info_expand}">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                            <div class="w-100" v-if="$route.query.branch == 'add_branch'">
                                                <span class="input-label d-flex align-items-center justify-content-between">
                                                <span>Branch E-mail<span class="pl-1 text-danger">*</span></span>
                                                    
                                                </span>
                                                <div class="d-flex align-items-center" style="padding-top: 3px !important;">
                                                    <div class="d-flex align-items-center input-field">
                                                        <input type="text"
                                                            class="form-control-email-dropdown w-100"
                                                            v-model="branch_email"
                                                            maxlength="100"
                                                            name="branch_email"
                                                            v-validate="{required: true,regex: /^[a-zA-Z0-9-_.]+$/}"
                                                            autocomplete="off"
                                                            placeholder="Enter Branch E-mail" />
                                                        <span class="input-group-addon-email"
                                                            style="font-size: 14px;">@</span>
                                                        <span class="fs-14">
                                                            {{company_branch_domain_name.replace(/.+\/\/|www.|\+/g, '')}}
                                                        </span>
                                                    </div>
                                                    <!-- v-validate="{required: true,email: true}" -->
                                                    <span class="pointer ml-1" @click="addAlternateEmail">
                                                        <i class="icon icon-plus-circle text-success"></i>
                                                    </span>
                                                </div>
                                                <span class="invalid-feedback-form text-danger" v-show="errors.has('branch_email')">
                                                    {{errors.first('branch_email')}}
                                                </span>
                                            </div>
                                            <div v-else class="w-100">
                                                <span class="input-label d-flex align-items-center justify-content-between">
                                                <span>Branch E-mail<span class="pl-1 text-danger">*</span></span>
                                                    
                                                </span>
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex align-items-center input-field">
                                                        <input type="text"
                                                            class="form-control-email-dropdown w-100"
                                                            v-model="branch_email"
                                                            maxlength="100"
                                                            name="branch_email"
                                                            v-validate="{required: true,email: true}"
                                                            autocomplete="off"
                                                            placeholder="Enter Branch E-mail" />
                                                    </div>
                                                    <span class="pointer ml-1" @click="addAlternateEmail">
                                                        <i class="icon icon-plus-circle text-success"></i>
                                                    </span>
                                                </div>
                                                <span class="invalid-feedback-form text-danger" v-show="errors.has('branch_email')">
                                                    {{errors.first('branch_email')}}
                                                </span>
                                            </div>
                                        </div>
                                        <div v-for="(alt_email, alt_email_index) in alternate_email" :key="alt_email_index+'atl_email'" class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                            <div class="w-100">
                                                <span class="input-label d-flex align-items-center justify-content-between">Alternative E-mail {{alt_email_index+1}}
                                                    
                                                </span>
                                                <div class="d-flex align-items-center" style="padding-top: 3px !important;">
                                                    <input type="text" maxlength="100" v-validate="'email'" v-model="alternate_email[alt_email_index]" class="input-field"
                                                        :placeholder="'Enter Alternative E-mail '+(alt_email_index+1)" data-vv-as="Alternate Email" name="branch_alternate_email" />
                                                        <span class="pointer ml-1" @click="deleteAlternateEmail(alt_email_index)">
                                                            <i class="icon icon-minus-circle text-danger"></i>
                                                        </span>
                                                </div>
                                                <span class="invalid-feedback-form text-danger" v-show="errors.has('branch_alternate_email'+alt_email_index)">
                                                    {{errors.first('branch_alternate_email'+alt_email_index)}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                            <span class="input-label">Branch Phone Number<span class="pl-1 text-danger">*</span></span>
                                            <div class="d-flex align-items-center mt-2">
                                                <div class="leftType ml-0">
                                                    <div class="d-flex">
                                                        <multiselect v-validate="'required'" v-model="branch_phone_type"
                                                            class="diginew-multiselect phone-number pointer multiselect-height-issue-fix"  id="ajax"
                                                            :options="branch_phone_type_options" 
                                                            :multiple="false" :searchable="true" :internal-search="true"
                                                            :show-labels="false" style="width: 70px !important;height: 38px !important;"
                                                            name="phone_type" placeholder=""
                                                            :clear-on-select="false" :close-on-select="true" :options-limit="280"
                                                            :max-height="200" :show-no-results="true" :hide-selected="false">
                                                            <template slot="singleLabel" slot-scope="props">
                                                                <img class="option__image" :src="props.option.image" :alt="props.option.name" style="width: 22px">
                                                            </template>
                                                            <template slot="option" slot-scope="{ option }">
                                                                <div class="d-flex flex-row">
                                                                    <img
                                                                        :src="option.image"
                                                                        style="width: 10px;" />
                                                                    <span class="ml-4">
                                                                        <span style="font-size: 14px">{{ option.name }}</span>
                                                                    </span>
                                                                </div>
                                                            </template>
                                                        </multiselect>
                                                    </div>
                                                </div>
                                                <div class="profile-primay-phone ml-2 w-100" style="position: relative;">
                                                    <div>
                                                        <vue-tel-input
                                                            name="branch_phone"
                                                            :defaultCountry="branch_phone_number.country_code"
                                                            :enabledCountryCode="false"
                                                            :disabledFormatting="false" :validCharactersOnly="true" :maxLen="15"
                                                            class="tele-contact-border-radius-none w-100" ref="telInput"
                                                            placeholder="Enter Branch Phone Number"
                                                            @onInput="branchPhone($event)" style="padding-left: 0px !important;"
                                                            v-validate="{required: true,min:10}"
                                                            v-model="branch_phone_number.phone_number">
                                                        </vue-tel-input>
                                                    </div>
                                                </div>
                                            </div>
                                            <span class="invalid-feedback-form text-danger" v-show="errors.has('branch_phone')">
                                                {{errors.first('branch_phone')}}
                                            </span>
                                        </div>
                                        <div v-for="(alterante_phone, alt_phone_index) in alternate_phone" :key="alt_phone_index+'alt'" class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                            <span class="input-label d-flex align-items-center justify-content-between">Alternate Phone Number {{alt_phone_index+1}}
                                                
                                            </span>
                                            <div class="d-flex align-items-center" style="padding-top: 3px !important;">
                                                <div class="leftType ml-0">
                                                    <div class="d-flex">
                                                        <multiselect v-model="alterante_phone.phone_type"
                                                            class="diginew-multiselect phone-number pointer multiselect-height-issue-fix" 
                                                            :options="branch_phone_type_options"
                                                            :multiple="false" :searchable="true" :internal-search="true"
                                                            :show-labels="false" style="width: 70px !important;height: 38px !important;" placeholder=""
                                                            :clear-on-select="false" :close-on-select="true" :options-limit="280"
                                                            :max-height="200" :show-no-results="true" :hide-selected="false">
                                                            <template slot="singleLabel" slot-scope="props">
                                                                <img class="option__image" :src="props.option.image" :alt="props.option.name" style="width: 22px">
                                                            </template>
                                                            <template slot="option" slot-scope="{ option }">
                                                                <div class="d-flex flex-row">
                                                                    <img
                                                                        :src="option.image"
                                                                        style="width: 10px;" />
                                                                    <span class="ml-4">
                                                                        <span style="font-size: 14px">{{ option.name }}</span>
                                                                    </span>
                                                                </div>
                                                            </template>
                                                        </multiselect>
                                                    </div>
                                                </div>
                                                <div class="profile-primay-phone ml-2 w-100" style="position: relative;">
                                                    <div>
                                                        <vue-tel-input
                                                            name="branch_alternate_phone"
                                                            :defaultCountry="branch_phone_number.country_code"
                                                            :enabledCountryCode="false"
                                                            :disabledFormatting="false" :validCharactersOnly="true" :maxLen="15"
                                                            class="tele-contact-border-radius-none w-100" ref="telInput"
                                                            :placeholder="'Enter Alternate Phone Number '+(alt_phone_index+1)"
                                                            @onInput="branchAltPhone($event, alt_phone_index)"
                                                            data-vv-as="Alternate Phone"
                                                            v-validate="{min:10}" style="padding-left: 0px !important;"
                                                            v-model="alterante_phone.phone_number">
                                                        </vue-tel-input>
                                                    </div>
                                                </div>
                                                <span v-if="alt_phone_index == 0" class="pointer ml-1" @click="addAlternatePhone">
                                                    <i class="icon icon-plus-circle text-success"></i>
                                                </span>
                                                <span v-else class="pointer ml-1" @click="deleteAlternatePhone">
                                                    <i class="icon icon-minus-circle text-danger"></i>
                                                </span>
                                            </div>
                                            <span class="invalid-feedback-form text-danger" v-show="errors.has('branch_alternate_phone'+alt_phone_index)">
                                                {{errors.first('branch_alternate_phone'+alt_phone_index)}}
                                            </span>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                            <div class="w-100">
                                                <span class="input-label">Website<span class="pl-1 text-danger">*</span></span>
                                                <div class="d-flex align-items-center mt-2">
                                                    <input type="text" maxlength="100" v-validate="{required: true, regex:/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/}" v-model="branch_website" class="input-field"
                                                        placeholder="Enter Website" name="branch_website" />
                                                </div>
                                                <span class="invalid-feedback-form text-danger" v-show="errors.has('branch_website')">
                                                    {{errors.first('branch_website')}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5">
                                <div class="create-company-header d-flex align-items-center justify-content-between">
                                    <span class="fs-14 text-white">Branch Address</span>
                                    <span class="pointer" @click="expandTabs('branch_address')">
                                        <i class="text-white" :class="{'icon-chevron-double-up':branch_address_expand, 'icon-chevron-double-down':!branch_address_expand}" style="font-size: 20px;"></i>
                                    </span>
                                </div>
                                <div :class="{'d-none':!branch_address_expand}">
                                    <div>
                                        <div class="row">
                                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                                <span class="input-label">Location</span>
                                                <div class="d-flex justify-content-between align-items-center mt-2">
                                                    <div class="form-group gMap pt-0 mb-0" style="position:relative;width:100%;">
                                                        <gmap-autocomplete
                                                            class="input-field mt-0"
                                                            id="autocompleteInput"
                                                            name="get_location"
                                                            placeholder="Search Location"
                                                            @place_changed="setPlace($event)"
                                                            :value="branch_address.location_vmodel"
                                                            v-model="branch_address.location_vmodel"
                                                            style="padding: 8px 32px 8px 18px !important; height: 40px;"
                                                        ></gmap-autocomplete>
                                                        <img
                                                            src="/static/images/marker_pin_new.svg"
                                                            style="position: absolute;right: 12px;bottom: 10px;"
                                                        />
                                                    </div>
                                                    <div class="form-group gMap ml-4 pt-0 mb-0" style="cursor: pointer;" @click="getLocation()">
                                                        <img src="/static/images/gps_new_white_back.svg" style="height: 40px;" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                                <div class="w-100">
                                                    <span class="input-label">Building No.</span>
                                                    <div class="d-flex align-items-center mt-2">
                                                        <input type="text" maxlength="10" v-model="branch_address.building_no" class="input-field"
                                                            placeholder="Enter Building No" name="building_no" />
                                                    </div>
                                                    <div class="text-right mr-4" style="position: absolute;right: 0;">
                                                        <span class="input-field-length">{{ (branch_address.building_no).length }}/10</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                                <div class="w-100">
                                                    <span class="input-label">Street 1</span>
                                                    <div class="d-flex align-items-center mt-2">
                                                        <input type="text" maxlength="100" v-model="branch_address.street" class="input-field"
                                                            placeholder="Enter Street 1" name="street1" />
                                                    </div>
                                                    <div class="text-right mr-4" style="position: absolute;right: 0;" >
                                                        <span class="input-field-length">{{ (branch_address.street).length }}/100</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                                <div class="w-100">
                                                    <span class="input-label">Street 2</span>
                                                    <div class="d-flex align-items-center mt-2">
                                                        <input type="text" maxlength="100" v-model="branch_address.street1" class="input-field"
                                                            placeholder="Enter Street 2" name="street2" />
                                                    </div>
                                                    <div class="text-right mr-4" style="position: absolute;right: 0;">
                                                        <span class="input-field-length">{{ (branch_address.street1).length }}/100</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                                <div class="w-100">
                                                    <span class="input-label">City</span>
                                                    <div class="d-flex align-items-center mt-2">
                                                        <input type="text" maxlength="100" v-model="branch_address.city" class="input-field"
                                                            placeholder="Enter City" name="city" />
                                                    </div>
                                                    <div class="text-right mr-4" style="position: absolute;right: 0;">
                                                        <span class="input-field-length">{{ (branch_address.city).length }}/100</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                                <div class="w-100">
                                                    <span class="input-label">Township</span>
                                                    <div class="d-flex align-items-center mt-2">
                                                        <input type="text" maxlength="100" v-model="branch_address.township" class="input-field"
                                                            placeholder="Enter Township" name="township" />
                                                    </div>
                                                    <div class="text-right mr-4" style="position: absolute;right: 0;">
                                                        <span class="input-field-length">{{ (branch_address.township).length }}/100</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                                <div class="w-100">
                                                    <span class="input-label">State</span>
                                                    <div class="d-flex align-items-center mt-2">
                                                        <input type="text" maxlength="100" v-model="branch_address.state" class="input-field"
                                                            placeholder="Enter State" name="state" />
                                                    </div>
                                                    <div class="text-right mr-4" style="position: absolute;right: 0;">
                                                        <span class="input-field-length">{{ (branch_address.state).length }}/100</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                                <span class="input-label">Country Name<span class="pl-1 text-danger">*</span></span>
                                                <div class="d-flex align-items-center mt-2">
                                                    <multiselect
                                                        :max-height="200"
                                                        name="country_namne"
                                                        maxlength="100"
                                                        class="diginew-multiselect w-100 multiselect-height-issue-fix"
                                                        v-model="branch_address.country"
                                                        @input="branch_address.state = '', branch_address.city = '',branch_address.street = '',branch_address.township = '',branch_address.zip_code = '',branch_address.location_vmodel = ''"
                                                        :options="countries"
                                                        @close="countryClose(branch_address.country)"
                                                        :searchable="true"
                                                        :close-on-select="true"
                                                        v-validate="'required'"
                                                        :show-labels="false"
                                                        placeholder="Select Country"
                                                    >
                                                        <template slot="noOptions">
                                                            <div class="text-secondary text-center fw-600">List is Empty</div>
                                                        </template>
                                                        <template slot="noResult">
                                                            <div class="text-secondary text-center fw-600">No Results Found</div>
                                                        </template>
                                                    </multiselect>
                                                </div>
                                                <div class="text-right mr-4" style="position: absolute;right: 0;">
                                                    <span class="input-field-length">{{ (branch_address.country).length }}/100</span>
                                                </div>
                                                <span class="invalid-feedback-form text-danger" v-show="errors.has('country_namne')">
                                                    {{errors.first('country_namne')}}
                                                </span>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                                <div class="w-100">
                                                    <span class="input-label">ZipCode</span>
                                                    <div class="d-flex align-items-center mt-2">
                                                        <input type="text" v-model="branch_address.zip_code" class="input-field"
                                                            placeholder="Enter ZipCode" name="zip_code" />
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <!-- </div> -->
                <div class="v-modal-dialog-actions pt-10 pb-6">
                    <div>
                        <h1 v-if="warning_msg" class="text-danger fs-14">{{ warning_msg }}</h1>
                        <div class="d-flex justify-content-center align-items-center my-6">
                            <button v-if="$route.query.branch == 'add_branch'" :disabled="upload_in_progress || save_api_call_in_progress" class="btn btn-outline-secondary" @click="cancelCreateBranch" style="width: 120px;border-radius: 5px;">Cancel</button>
                            <button v-else class="btn btn-outline-secondary" :disabled="upload_in_progress || save_api_call_in_progress"  @click="cancel" style="width: 120px;border-radius: 5px;">Cancel</button>
                            <button v-if="$route.query.branch == 'add_branch'" :disabled="upload_in_progress || save_api_call_in_progress || fields_not_changed" class="btn btn-new-success ml-5" @click="addBranch" style="width: 120px;border-radius: 5px;">Save
                                <hollow-dots-spinner v-if="upload_in_progress || save_api_call_in_progress" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                            </button>
                            <button v-else class="btn btn-new-success ml-5" :disabled="upload_in_progress || save_api_call_in_progress || fields_not_changed" @click="updateBranch" style="width: 120px;border-radius: 5px;">Save
                                <hollow-dots-spinner v-if="upload_in_progress || save_api_call_in_progress" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                            </button>
                        </div>
                    </div>
                </div>
            <!-- </div>
        </modal> -->
        <go-back-popup @hideModal="hideModal" @goBack="goBack" v-if="goBackModel" modal_name="Go_Back"></go-back-popup>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>

<script>
import { HollowDotsSpinner } from "epic-spinners";
import countries from "../../json/countries.json";
import companies from '../../mixins/companies';
import timezone from '../../json/timezone.json';
import { SweetModal } from 'sweet-modal-vue';
import GoBackPopup from './GoBackPopup'
import axios from 'axios';
export default {
    data() {
        return {
            goBackModel: false,
            upload_in_progress: false,
            save_api_call_in_progress: false,
            assign_as_headquarter: false,
            save_create_btn_hide: false,
            branch_info_expand: true,
            branch_address_expand: true,
            communication_info_expand: true,
            create_api_called: false,
            industry_type_options: [],
            time_zone_options: timezone,
            time_zone: {},
            warning_msg: '',
            success_msg: '',
            branch_name: '',
            branch_code: '',
            alternate_email: [],
            branch_email: '',
            date_of_incorporation: '',
            company_branch_domain_name: '',
            no_of_employees: 0,
            loading: false,
            branch_phone_number: {
                phone_type: {
                    name: 'Work',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg'
                },
                phone_number: "",
                country_code: "US",
                dial_code: "1"
            },
            alternate_phone: [
                {
                    phone_type: {
                        name: 'Work',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg'
                    },
                    phone_number: "",
                    country_code: "US",
                    dial_code: "1"
                }
            ],
            branch_website: '',
            branch_phone_type: {
                name: 'Work',
                image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg'
            },
            branch_alternate_phone_type: {
                name: 'Work',
                image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg'
            },
            branch_phone_type_options: [
                {
                    name: 'Home',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/home-contact-icon.svg',
                    width: 20,
                },
                {
                    name: 'Mobile',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/mobile-contact-icon.svg'
                },
                {
                    name: 'Whatsapp',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/whatsapp-contact-icon.svg'
                },
                {
                    name: 'Line',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/line-contact-icon.svg'
                },
                {
                    name: 'Work',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg'
                },
                {
                    name: 'Telegram',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/telegram-contact-icon.svg'
                },
                {
                    name: 'Others',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/others-contact-icon.svg'
                },
                {
                    name: 'Messenger',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/messenger-contact-icon.svg'
                },
                {
                    name: 'Fax',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/fax-contact-icon.svg'
                }
            ],
            countries: countries,
            branch_address: {
                building_no: "",
                street: "",
                street1: "",
                city: "",
                township: "",
                state: "",
                country: "",
                zip_code: "",
                address_type: "Current Address",
                location_vmodel: ""
            },
            branch_info: null,
            stringified_response: ""
        }
    },
    props: ["modal_name"],
    components: {
        SweetModal, GoBackPopup, HollowDotsSpinner
    },
    mixins: [companies],
    beforeRouteLeave (to, from, next) {
            if (this.fields_not_changed == false) {
                let message = "<img src='/static/images/exclamation-yellow.svg' style='width: 55px;margin-bottom: 15px;' /><br><div class='d-block'>Unsaved changes would be lost, Do you want to proceed?</div>";
                let options = {
                    html: true,
                    loader: false,
                    reverse: false, // switch the button positions (left to right, and vise versa)
                    okText: 'Yes',
                    cancelText: 'No',
                    animation: 'fade',
                    type: 'basic',
                    verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
                    verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
                    backdropClose: true,
                };
                this.$dialog.confirm(message, options)
                .then(function () {
                    next();
                })
                .catch(function () {
                    next(false);
                });
            } else {
                next()
            }
        },
    methods: {
        showGoBackPopup() {
            this.goBackModel = true
            setTimeout(() => {
                this.$modal.show('Go_Back')
            }, 500)
        },
        hideModal() {
            this.goBackModel = false;
        },
        updateBranch() {
            this.$validator.validateAll().then(result => {
                if (result == true) {
                    this.updateData();
                }
            })
        },
        async updateData() {
            try {
                let params = {
                };
                params.name = this.branch_name;
                params.code = this.branch_code;
                params.date_of_incorporation = this.date_of_incorporation ? this.date_of_incorporation.split("/")[2]+"-"+this.date_of_incorporation.split("/")[1]+"-"+this.date_of_incorporation.split("/")[0] : ""
                params.no_of_employees = this.no_of_employees
                params.building_no = this.branch_address.building_no
                params.street1 = this.branch_address.street
                params.street2 = this.branch_address.street1
                params.city = this.branch_address.city
                params.email = this.branch_email
                params.phone_number = {
                    id: this.branch_data.phone_number.id,
                    phone_type: this.branch_phone_type.name,
                    phone_number: this.branch_phone_number.phone_number,
                    country_code: this.branch_phone_number.country_code,
                    dial_code: this.branch_phone_number.dial_code,
                }
                var phoneNumberDetails = []
                this.alternate_phone.forEach((el, index)=>{
                    phoneNumberDetails = {
                            id: this.branch_data.alternate_phone[index].id,
                            phone_type: this.alternate_phone[index].phone_type,
                            phone_number: this.alternate_phone[index].phone_number,
                            country_code: this.alternate_phone[index].country_code,
                            dial_code: this.alternate_phone[index].dial_code,
                        }
                        this.alternate_phone[index] = phoneNumberDetails
                    })
                params.alternate_phone = this.alternate_phone
                // params.alternate_phone = [
                //     {
                //         id: this.branch_data.alternate_phone[index].id,
                //         phone_type: this.edit_user_data.alternate_phone[index].phone_type,
                //         phone_number: this.edit_user_data.alternate_phone[index].phone_number,
                //         country_code: this.edit_user_data.alternate_phone[index].country_code,
                //         dial_code: this.edit_user_data.alternate_phone[index].dial_code,
                //     }
                // ]
                params.alternate_email = this.alternate_email;
                params.website = this.branch_website;
                params.state = this.branch_address.state;
                params.township = this.branch_address.township;
                params.country = this.branch_address.country;
                params.zip_code = this.branch_address.zip_code;
                params.time_zone = this.time_zone.label;
                params.is_headquarter = this.assign_as_headquarter;
                this.save_api_call_in_progress = true
                let response = await this.updateBranchById(params, this.$route.query.branch);
                if(response.status_id == 1) {
                    this.success_msg = response.message
                    this.$refs.success_modal.open();
                    setTimeout(() => {
                        this.$refs.success_modal.close();
                        this.goBack();
                    }, 2000);
                    this.save_api_call_in_progress = false
                }
            }
            catch(err) {
                this.warning_msg = err;
                this.$refs.warning_modal.open();
            }
        },
        setAsHeadquarter() {
            setTimeout(() => {
                this.updateInfo('assign_as_headquarter');
            }, 500);
        },
        async getBranchDataById() {
            try {
                let response = await this.getBranchById(this.$route.query.branch);
                if(response.status_id == 1) {
                    this.branch_info = JSON.parse(JSON.stringify(response.response))
                    // this.getListViewData();
                    let data = response.response
                    this.branch_data = response.response;
                    this.branch_name = data.name;
                    this.no_of_employees = data.no_of_employees;
                    this.branch_code = data.code;
                    this.date_of_incorporation = data.date_of_incorporation ? data.date_of_incorporation.split('-')[2]+'/'+data.date_of_incorporation.split('-')[1]+'/'+data.date_of_incorporation.split('-')[0] : '';
                    this.time_zone = {
                        label: data.time_zone,
                        value: ''
                    };
                    this.branch_email = data.email
                    this.alternate_email = data.alternate_email;
                    this.branch_phone_number.phone_number = data.phone_number.phone_number;
                    this.alternate_phone = data.alternate_phone;
                    this.branch_website = data.website;
                    this.branch_number_type = data.phone_number.phone_type;
                    this.branch_address.building_no = data.building_no;
                    this.branch_address.street = data.street1;
                    this.branch_address.street1 = data.street2;
                    this.branch_address.city = data.city;
                    this.branch_address.township = data.township;
                    this.branch_address.state = data.state;
                    this.branch_address.country = data.country;
                    this.branch_address.zip_code = data.zip_code;
                    this.assign_as_headquarter = data.is_headquarter;
                }
                this.ajax_call_in_progress = false;
                this.stringified_response = JSON.stringify(this.create_data)
            }
            catch(err) {
            }
        },
        cancelCreateBranch(index){
            
            this.branch_name = "";

            this.branch_code = "";
        
            this.date_of_incorporation = "";
        
            this.no_of_employees = "";
        
            this.branch_address.building_no = "";

            this.branch_address.state = "";
        
            this.branch_address.street = "";
        
            this.branch_address.street1 = "";
        
            this.branch_address.city = "";
        
            this.branch_email = "";
            this.branch_website = "";
        
            this.branch_number = "";
        
            // this.alternate_phone[index].phone_number = branch_data.alternate_phone[index] == undefined || null ? '' : branch_data.alternate_phone[index].phone_number;
        
            this.alternate_email[index] = "";
        
            this.website = "";
        
            this.branch_address.state = "";
        
            this.branch_address.township = "";
        
            this.branch_address.country = "";
        
            this.branch_address.zip_code = "";
            this.$router.go(-1);
            // this.time_zone.label = branch_data.time_zone == undefined || null ? '' : branch_data.time_zone;
        },
        cancel(index){
            let branch_data = this.branch_info;
            this.branch_name = branch_data.name == undefined || null ? '' : branch_data.name;

            this.branch_code = branch_data.code == undefined || null ? '' : branch_data.code
        
            this.date_of_incorporation = branch_data.date_of_incorporation == undefined || null ? '' : branch_data.date_of_incorporation.split('-')[2]+'/'+branch_data.date_of_incorporation.split('-')[1]+'/'+branch_data.date_of_incorporation.split('-')[0];
        
            this.no_of_employees = branch_data.no_of_employees == undefined || null ? '' : branch_data.no_of_employees;
        
            this.branch_address.building_no = branch_data.building_no == undefined || null ? '' :  branch_data.building_no;
        
            this.branch_address.street = branch_data.street1 == undefined || null ? '' : branch_data.street1;
        
            this.branch_address.street1 = branch_data.street2 == undefined || null ? '' : branch_data.street2;
        
            this.branch_address.city = branch_data.email == undefined || null ? '' : branch_data.city;
        
            this.branch_email = branch_data.email == undefined || null ? '' : branch_data.email;
            this.branch_website =branch_data.website == undefined || null ? '' : branch_data.website;
        
            // this.branch_number = branch_data.phone_number == undefined || null ? '' : branch_data.phone_number;
        
            // this.alternate_phone[index].phone_number = branch_data.alternate_phone[index] == undefined || null ? '' : branch_data.alternate_phone[index].phone_number;
        
            this.alternate_email[index] = branch_data.alternate_email[index] == undefined || null ? '' : branch_data.alternate_email[index];
        
            this.website = branch_data.website == undefined || null ? '' : branch_data.website;
        
            this.branch_address.state = branch_data.state == undefined || null ? '' : branch_data.state;
        
            this.branch_address.township = branch_data.township == undefined || null ? '' : branch_data.township;
        
            this.branch_address.country = branch_data.country == undefined || null ? '' : branch_data.country;
        
            this.branch_address.zip_code = branch_data.zip_code == undefined || null ? '' : branch_data.zip_code;
                
            this.$router.go(-1);
            // this.time_zone.label = branch_data.time_zone == undefined || null ? '' : branch_data.time_zone;
                
        },
        cancelNew(){
            window.history.back();
        },
        goBack() {
            window.history.back();
        },
        hideCreateBranch() {
            setTimeout(() => {
                this.$modal.hide(this.modal_name);
            }, 500);
            this.$emit("hideCreateBranch");
        },
        saveCreateBranch() {
            
        },
        branchPhone({number,isValid,country}) {
            this.branch_phone_number.phone_number = number.national;
            this.branch_phone_number.dial_code = country.dialCode;
            this.branch_phone_number.country_code = country.iso2;
        },
        branchAltPhone({number,isValid,country}, phoneindex) {
            this.alternate_phone[phoneindex].phone_number = number.national;
            this.alternate_phone[phoneindex].dial_code = country.dialCode;
            this.alternate_phone[phoneindex].country_code = country.iso2;
        },
        expandTabs(type) {
            if(type == 'branch_info') {
                this.branch_info_expand = !this.branch_info_expand;
            } else if(type == 'communication_info') {
                this.communication_info_expand = !this.communication_info_expand;
            } else if(type == 'branch_address') {
                this.branch_address_expand = !this.branch_address_expand;
            }
        },
        addAlternatePhone() {
            this.alternate_phone.push(
                {
                    phone_type: {
                        name: 'Work',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg'
                    },
                    phone_number: "",
                    country_code: "US",
                    dial_code: "1"
                }
            );
        },
        deleteAlternatePhone(index) {
            this.alternate_phone.splice(index, 1);
        },
        addAlternateEmail() {
            this.alternate_email.push("");
        },
        deleteAlternateEmail(index) {
            this.alternate_email.splice(index, 1);
        },
        countryClose(data) {
            this.branch_address.country = data;
            this.$forceUpdate();
        },
        setPlace(place) {
            let address = place.formatted_address.split(", ");
            this.branch_address.street =
                address[address.length - 6] || "";
            this.branch_address.township =
                address[address.length - 4] || "";
            this.branch_address.city = address[address.length - 3] || "";
            let statezip = address[address.length - 2]
                ? address[address.length - 2].split(" ")
                : ["", ""];
            if (statezip.length == 1) {
                if (statezip[0].match(/^[0-9]+$/)) {
                this.branch_address.zip_code = statezip[0];
                } else {
                this.branch_address.state = statezip[0];
                }
            } else {
                this.branch_address.zip_code = statezip[0].match(/^[0-9]+$/)
                ? statezip[0]
                : statezip[1].match(/^[0-9]+$/)
                ? statezip[1]
                : "";
                this.branch_address.state = statezip[0].match(/^[a-zA-Z]+$/)
                ? statezip[0]
                : statezip[1].match(/^[a-zA-Z]+$/)
                ? statezip[1]
                : "";
            }
            if (address[address.length - 1].includes("-")) {
                let countrySelected = address[address.length - 1].split(" - ");
                countrySelected.forEach(x => {
                if (this.countries.includes(x)) {
                    this.branch_address.country = x;
                } else {
                    this.branch_address.country = "";
                }
                });
            } else {
                this.branch_address.country = address[address.length - 1];
            }
            // this.branch_address.address.latitude = currentPlace.geometry.location.lat() || "";
            // this.branch_address.address.longitude = currentPlace.geometry.location.lng() || "";
            this.branch_address.location_vmodel = place.formatted_address;
            this.$forceUpdate();
        },
        getLocation() {
            // this.addressIndex = index;
            if (navigator.geolocation) {
                // timeout at 60000 milliseconds (60 seconds)
                var options = {
                enableHighAccuracy: false,
                timeout: 30000, // milliseconds (30 seconds)
                maximumAge: 600000
                }; // milliseconds (10 minutes)
                navigator.geolocation.getCurrentPosition(
                this.showLocation,
                this.errorHandler,
                options
                );
            } else {
                alert("Sorry, browser does not support geolocation!");
            }
        },
        showLocation(position) {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            let latlongvalue = position.coords.latitude + "," + position.coords.longitude;
            let calllatlong = axios.create();
            delete calllatlong.defaults.headers.common['Authorization'];
            calllatlong.post("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latlongvalue + "&key=AIzaSyCQSEwELtCrUrLSfsqU0wX7QAHbLVQzUrY")
            .then(response => {
                let address = response.data.results[0].formatted_address.split(", ");
                this.branch_address.street =
                address[address.length - 6] || "";
                this.branch_address.township =
                address[address.length - 4] || "";
                this.branch_address.city = address[address.length - 3] || "";
                let statezip = address[address.length - 2]
                ? address[address.length - 2].split(" ")
                : ["", ""];
                this.branch_address.zip_code = statezip[0].match(/^[0-9]+$/)
                ? statezip[0]
                : statezip[1].match(/^[0-9]+$/)
                ? statezip[1]
                : "";
                this.branch_address.state = statezip[0].match(/^[a-zA-Z]+$/)
                ? statezip[0]
                : statezip[1].match(/^[a-zA-Z]+$/)
                ? statezip[1]
                : "";
                if (address[address.length - 1].includes("-")) {
                let countrySelected = address[address.length - 1].split(" - ");
                countrySelected.forEach(x => {
                    if (this.countries.includes(x)) {
                    this.branch_address.country = x;
                    } else {
                    this.branch_address.country = "";
                    }
                });
                } else {
                this.branch_address.country = address[address.length - 1];
                }
                this.branch_address.location_vmodel = response.data.results[0].formatted_address;
                this.$forceUpdate();
            })
            .catch(e => {
                // this.errors.push(e)
            });
        },
        errorHandler(err) {
            if (err.code == 1) {
                // alert("Error: Access is denied!");
            } else if (err.code == 2) {
                // alert("Error: Position is unavailable!");
            }
        },
        async fetchDropdownsData(type) {
            this.loading = true;
            try {
                let param = type;
                let response = await this.getDropdownsData(param);
                this.loading = false;
                if(response.status_id == 1) {
                    if(type == 'industry_type') {
                        this.industry_type_options = response.response;
                    }
                }
            }
            catch(err) {
                this.loading = false;
            }
        },
        async createNewBranch() {
            this.create_api_called = true;
            try {
                let params = {
                        };
                        
                        params.name = this.branch_name;
                    
                        params.code = this.branch_code;
                    
                        params.date_of_incorporation = this.date_of_incorporation ? this.date_of_incorporation.split("/")[2]+"-"+this.date_of_incorporation.split("/")[1]+"-"+this.date_of_incorporation.split("/")[0] : ""
                    
                        params.no_of_employees = this.no_of_employees
                    
                        params.building_no = this.branch_address.building_no
                    
                        params.street1 = this.branch_address.street
                    
                        params.street2 = this.branch_address.street1
                    
                        params.city = this.branch_address.city
                    
                        params.email = this.branch_email+'@'+this.company_branch_domain_name.replace(/.+\/\/|www.|\+/g, ''),
                    
                        params.phone_number = {
                            // id: this.branch_data.phone_number.id,
                            phone_type: this.branch_phone_type.name,
                            phone_number: this.branch_phone_number.phone_number,
                            country_code: this.branch_phone_number.country_code,
                            dial_code: this.branch_phone_number.dial_code,
                        }
                        var phoneNumberDetails = []
                        this.alternate_phone.forEach((el, index)=>{
                            phoneNumberDetails = {
                                    // id: this.branch_data.alternate_phone[index].id,
                                    phone_type: this.alternate_phone[index].phone_type.name,
                                    phone_number: this.alternate_phone[index].phone_number,
                                    country_code: this.alternate_phone[index].country_code,
                                    dial_code: this.alternate_phone[index].dial_code,
                                }
                                this.alternate_phone[index] = phoneNumberDetails
                            })
                        params.alternate_phone = this.alternate_phone
                        // params.alternate_phone = [
                        //     {
                        //         id: this.branch_data.alternate_phone[index].id,
                        //         phone_type: this.edit_user_data.alternate_phone[index].phone_type,
                        //         phone_number: this.edit_user_data.alternate_phone[index].phone_number,
                        //         country_code: this.edit_user_data.alternate_phone[index].country_code,
                        //         dial_code: this.edit_user_data.alternate_phone[index].dial_code,
                        //     }
                        // ]
                    
                        params.alternate_email = this.alternate_email;
                    
                        params.website = this.branch_website;
                    
                        params.state = this.branch_address.state;
                    
                        params.township = this.branch_address.township;
                    
                        params.country = this.branch_address.country;
                    
                        params.zip_code = this.branch_address.zip_code;
                    
                        params.time_zone = this.time_zone.label;
                    
                        params.is_headquarter = this.assign_as_headquarter;
                        params.company_id = this.$route.params.company_id
                        this.save_api_call_in_progress = true
                        let response = await this.createBranch(params);
                        if(response.status_id == 1) {
                                this.success_msg = response.message
                                this.$refs.success_modal.open();
                                setTimeout(() => {
                                    this.$refs.success_modal.close();
                                    this.goBack();
                                }, 2000);
                                this.save_api_call_in_progress = false
                                // EventBus.$emit('personal_info_data_update')
                            }
                        // if(response.status_id == 1) {
                        //     this.hideCreateBranch();
                        // }
                    }
            catch(err) {
                this.create_api_called = false;
                this.save_api_call_in_progress = false
                this.warning_msg = err;
            }
        },
        addBranch() {
            this.$validator.validateAll().then(result => {
                    if (result == true) {
                    // this.create_data.alternate_phone.forEach((ph, index) => {
                    //    ph.dial_code = ph.phone_number.split(" ")[0] || "+91";
                    //    ph.phone_type = ph.phone_type.name;
                    // })
                    this.createNewBranch();
                }
            })
        },
    },
    mounted() {
        if(this.$route.query.branch != 'add_branch'){
            this.getBranchDataById();
        }
        let tz = new Date().getTimezoneOffset().toString();
        if (tz == "-330") {
            this.time_zone = {
                label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi (Asia/Kolkata)",
                value: "Asia/Kolkata",
            };
        } else if (tz == "-420") {
            this.time_zone = {
                label: "(GMT+07:00) Bangkok, Hanoi, Jakarta (Asia/Bangkok)",
                value: "Asia/Bangkok",
            };
        }
        this.company_branch_domain_name = localStorage.getItem("company_branch_domain_name");
    },
    watch: {
    },
    computed: {
        fields_not_changed(){
            return this.stringified_response == JSON.stringify(this.create_data)
        },
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
        // account() {
        //     return this.$store.getters.account;
        // },
        // selectedCompany() {
        //     return this.$store.getters.selectedCompany;
        // },
        create_data() {
            return {
                name: this.branch_name,
                code: this.branch_code,
                date_of_incorporation: this.date_of_incorporation ? this.date_of_incorporation.split("/")[2]+"-"+this.date_of_incorporation.split("/")[1]+"-"+this.date_of_incorporation.split("/")[0] : "",
                no_of_employees: this.no_of_employees,
                time_zone: this.time_zone.label,
                email: this.branch_email+'@'+this.company_branch_domain_name.replace(/.+\/\/|www.|\+/g, ''),
                alternate_email: this.alternate_email,
                website: this.branch_website,
                building_no: this.branch_address.building_no,
                street1: this.branch_address.street,
                street2: this.branch_address.street1,
                city: this.branch_address.city,
                township: this.branch_address.township,
                state: this.branch_address.state,
                country: this.branch_address.country,
                zip_code: this.branch_address.zip_code,
                phone_number: {
                    phone_type: this.branch_phone_type.name,
                    phone_number: this.branch_phone_number.phone_number,
                    country_code: this.branch_phone_number.dial_code,
                    dial_code: this.branch_phone_number.dial_code
                },
                alternate_phone: this.alternate_phone,
                company_id: this.$route.params.company_id
            }
        }
    },
    created() {
        const dict = {
            custom: {
                branch_name:{
                    required:() => "Branch name is required",
                },
                time_zone:{
                    required:() => "Time zone is required",
                },
                branch_email:{
                    required:() => "Email is required",
                    regex:() => "Enter valid email address.",
                    email:() => "Enter valid email address.",
                },
                branch_alternate_email:{
                    email: () => "Enter valid email address.",
                },
                branch_alternate_phone:{
                    min: () => "Phone Number must contain more than 9 numbers.",
                },
                branch_phone:{
                    required:() => "Primary phone is required",
                    min: () => "Phone Number must contain more than 9 numbers.",
                },
                no_of_employees:{
                    numeric:() => "Please enter number.",
                },
                country_namne:{
                    required:() => "Country is required",
                },
                branch_website:{
                    required:() => "Website is required",
                    regex:() => "Enter valid website.",
                    url:() => "Enter valid website.",
                },
            }
        }
        this.$validator.localize('en', dict)
    }
}
</script>
<style scoped>
    .btn-outline-secondary {
        color: #e82828;
        border: 1px solid #e82828 !important;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #e82828;
        border-color: #e82828 !important;
    }
	.v-modal-header {
		display: -webkit-box;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		-webkit-box-pack: justify;
		-webkit-justify-content: center;
		-ms-flex-pack: justify;
		justify-content: center;
        color: #005dae !important;
		padding: 15px;
		border-bottom: 0px solid #E9ECEF;
		-webkit-border-top-left-radius: 15px;
		border-top-left-radius: 15px;
		-webkit-border-top-right-radius: 15px;
		border-top-right-radius: 15px;
		background-color: #f5f6f9 !important;
	}

	.v-modal-content {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100%;
		pointer-events: auto;
		background-color: #fff;
		-webkit-background-clip: padding-box;
		background-clip: padding-box;
		border: 0px solid rgba(0, 0, 0, 0.2);
		-webkit-border-radius: 2px;
		border-radius: 2px;
		-webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
		box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
		outline: 0;
	}

	.v-modal-dialog-title.v-modal-title {
		letter-spacing: .005em;
	}

	.v-modal-dialog-title {
		margin-bottom: 0px !important;
		font-size: 15px !important;
		font-weight: 600 !important;
		font-style: normal;
		display: block;
		font-stretch: normal;
		line-height: normal;
		color: #2b6ad0 !important;
		text-transform: uppercase !important;
		margin: 0 auto !important;
		padding: 4px !important;
	}

	.v-modal-body {
		overflow: auto;
		width: 100%;
		position: relative;
		-webkit-box-flex: 1;
		-webkit-flex: 1 1 auto;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		padding: 15px;
	}

	.new-modal-default .v-modal-header {
		background-color: #005dae !important;
		height: 50px;
        color: #fff;
        display: flex;
	}

    .v-modal-dialog-title {
        color: #fff !important;
    }

	/* .v-modal-layout {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
		} */
	.v-modal-dialog-actions {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		padding: 15px;
		border-top: 0px solid transparent;
	}
    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
        margin-left: -20px;
        margin-right: -15px;
    }

    .vue-tel-input{
        display:flex;
    }
    .input-field, .input-border, .vue-tel-input {
        border-radius: 6px !important;
        border: solid 0.5px #3f3f3f !important;
        background-color: #fff;
        padding: 5px 10px !important;
        width: 100% !important;
        height: 38px !important;
    }
    .profile-date-picker {
        padding: 5px 10px !important;
    }
    .arrow-circle{
        border-radius: 50%;
        background-color: #19181c;
        color: #fff;
        padding: 4px 6px 4px 6px;
        width: 30px;
        cursor: pointer;
    }
    .icon-plus-circle {
        color: #008a63 !important;
    }
    .mx-input-wrapper {
        padding: 4px 0px !important;
    }
    .btn-new-success:disabled{
        cursor: no-drop !important;
    }
    .btn:disabled{
        pointer-events: auto !important
    }
</style>